import vue from 'vue'
import Vuex from 'vuex'
import createpersisted from "vuex-persistedstate"

vue.use(Vuex)


export default new Vuex.Store({
    state: {
        menu: []
    },
    mutations: {
        // commit
        setMenu(state, menuList) {
            state.menu = menuList
        }
    },
    actions: {
        // dispatch
    },
    getters: {
        getMenu(state) {
            return state.menu
        }
    },
    plugins: [
        createpersisted({
            key: "pjrp",
        })
    ]
})