import VueRouter from 'vue-router';
const routes = [{
        path: '/',
        name: 'login',
        component: () =>
            import ('../components/Login')
    },
    {
        path: '/Index',
        name: 'index',
        component: () =>
            import ('../components/Index'),
        children: [{
                path: '/Home',
                name: 'home',
                meta: {
                    title: '首页'
                },
                component: () =>
                    import ('../components/Home')
            }, {
                path: '/User',
                name: 'User',
                component: () =>
                    import ('../components/user/UserManage')
            },


            {
                path: '/Shop',
                name: 'shop',
                component: () =>
                    import ('../components/shop/Shop')
            },
            {
                path: '/Drama',
                name: 'drama',
                component: () =>
                    import ('../components/drama/Drama')
            },
            {
                path: '/ShopDrama',
                name: 'drama',
                component: () =>
                    import ('../components/shopdrama/ShopDrama')
            },
            {
                path: '/Admin',
                name: 'admin',
                component: () =>
                    import ('../components/admin/Admin')
            }, {
                path: '/Label',
                name: 'label',
                component: () =>
                    import ('../components/label/Label')
            },
            {
                path: '/Host',
                name: 'host',
                component: () =>
                    import ('../components/host/Host')
            }, {
                path: '/Room',
                name: 'room',
                component: () =>
                    import ('../components/room/Room')
            }
        ]
    }
]

const router = new VueRouter({
    mode: 'history',
    routes
})

export function resetRouter() {
    router.matcher = new VueRouter({
        mode: 'history',
        routes: []
    }).matcher
}

// 解决重复点击同一个路由时，控制台报错
const VueRouterPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(to) {
    return VueRouterPush.call(this, to).catch(err => err)
}
export default router;